/* @import '~antd/dist/antd.css'; */

/* @import 'antd/dist/antd.dark.css'; */
@import url("https://fonts.googleapis.com/earlyaccess/cwtexyen.css");
@import url('https://fonts.googleapis.com/earlyaccess/notosanstc.css');
@import '~antd/dist/antd.variable.css';

:root{
  /* 每頁上方到麵包的 padding */
  --pages-padding-top: 0.25rem;
  --main-color: #D73A63;
  --input-border-radius: 4px;
  --input-bg-color: #F0F0F0;
  --modal-header-border-radius: 10px 10px 0 0;
  --modal-content-border-radius: 10px;
}

html {
  background-color: #ededed;
  font-size: 10vmin;
}

#root {
  position: fixed;
  height: 100%;
  width: 100%;

}

#root > .app {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

/** 從這裡開始客製化 */
/** 刪除input背景色 */
input:-webkit-autofill {
  transition-delay: 1e10s;
  font-size: 30px;
}

#root > .app {
  /* font-family: "cwTeXYen", "Helvetica Neue", Arial, Helvetica, PingFangSC-Regular, 微軟雅黑體, "Microsoft YaHei", 微軟正黑體, "Microsoft JhengHei", "Heiti SC", "Heiti TC", STXihei, "LiHei Pro", sans-serif; */
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  /*background: linear-gradient(#1e1e2f,#1e1e24);*/
  background: rgb(0, 21, 41);
  color: #fff;
  transition: background 0.2s ease-out;
}
#root > .app .ant-layout, #root > .app .ant-layout-content {
  /* background: transparent!important; */
}

#root > .app .ant-card {
  /* background: #191c24!important; */
}

.btn_warning{
  /* background:#fdba02;
  border:1px solid #fdba02; */
  background:#fff;
  border:1px solid #fff;
  color:#191c24
}

.btn_warning:hover{
  /* background:#fccc47;
  border:1px solid #fccc47; */
  background:#dbdbdb;
  border:1px solid #dbdbdb;
}

/* 上方選擇的Tag */
.topDiv{
  /* padding:8px 16px; */
  background: #dbdbdb;
  white-space: nowrap;
  width:100%;
}

.topDiv .ant-tabs-nav{
  margin-bottom: 0;
}

.topDiv .ant-tabs-tab{
  padding: 4px 8px!important;
  cursor: pointer;
  background: #d9d9d9 !important;
  border: none !important;
  color: #5A5A5A!important;
  border-radius: 8px 8px 0px 0px!important;
  -webkit-border-radius: 8px 8px 0px 0px!important;
  -moz-border-radius: 8px 8px 0px 0px!important;
  -ms-border-radius: 8px 8px 0px 0px!important;
  -o-border-radius: 8px 8px 0px 0px!important;
  border:2px solid #9545a2;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before{
  border: none;
}

.topDiv .ant-ant-tabs-tab:hover{
  background:#9545a2!important;
  color: #fff;
  font-weight: bolder;
}

.topDiv .ant-tabs-tab.ant-tabs-tab-active{
  background:#fff!important;
  font-weight: bolder;
}

.topDiv .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #D73A63!important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
  border-bottom-color: transparent;
}

/* .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
background: #dbdbdb;
border:0px
} */

/* BreadCrumb*/
.ant-breadcrumb {
  background: #fff;
  padding: 6px 0 9px 24px;
}
/* Header */
.ant-layout-header{
  padding: 0;
}

/* 表格表頭 */
.ant-table-thead > tr > th{
  text-align: center;
}
/* 表格row */
.ant-table-tbody > tr > td{
  text-align: center;
}

/* pagination  */
.ant-pagination.ant-table-pagination{
  position: fixed;
  bottom: 0;
  right: 0.5rem;
}
/* .ant-pagination-item-active{
  background:#9545a2;
  border: none;
  color: #fff!important;
}
.ant-pagination-item-active a{
  color:#fff
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  background:#26212a;
  color: #fff;
  border:none
}
.ant-pagination-item-ellipsis{
  color: #9545a2 !important;;
} */
/** Switch */
.ant-switch{
  background: linear-gradient(to bottom, #797979, #848484)!important;
}
.ant-switch.ant-switch-checked{
  background: #d25555!important;
}

/** Side Menu 區塊 */
.ant-menu{
  /* background: #1b062d; */
  color: #fff;
  border:none
}
/* .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu-selected,
.ant-menu-item.ant-menu-item-selected,
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: #9545a2;
}
.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child{
  background:transparent
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
  /*background: #26212a;
  background: #1b062d;
  margin: 0;
}
.ant-menu-inline .ant-menu-item-selected::after {
  /*opacity: 0;
  color: #26212a;
}  */

/** Content 區塊 */
.ant-layout-content {
  background: #dbdbdb!important;
}

.ant-form-item-label > label{
  /* color: #6e6d6d */
}

.ant-input{
  /* background: #bfbfbf; */
  /* border:none; */
  color:#10041b;
}

.ant-input-number{
  /* background: #bfbfbf; */
  width: 100%;
  /* border:none; */
  color:#10041b;
}

.ant-input-number-input{
  /* background: #bfbfbf; */
  /* border:none; */
  color:#10041b;
}

.ant-picker{
  /* background: #bfbfbf; */
}

.ant-select-selector{
  /* width: 2rem!important; */
  font-size: 16px!important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background: #bfbfbf;
  border:none;
  color:#10041b;
  font-family: "STYuanti-TC";
  font-size:12px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
  /* background: #2a3038!important; */
}

.ant-select-arrow{
  color:#F33569
}

.ant-select-dropdown {
  background: #bfbfbf
}
.ant-select-item.ant-select-item-option{
  color: #fff
}
.ant-select-item.ant-select-item-option.ant-select-item-option-selected{
  background: #3a434e;
  color: #F33569;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active{
  background: #3a434e;
  color: #F33569;
}

.ant-card-head{
  border-bottom: none
}

.ant-card-cover{
  /* background:#26212a; */
}

.ant-checkbox-inner{
  background:#191c24;
  border:#191c24;
}

/* modal  */
.ant-modal-body {
  /* background: #26212a; */
}
.ant-modal-close-x {
  color: gray;
}

/* form items 不符合格式的錯誤提示文字 */
.ant-form-item-explain-error {
  font-size: 12px;
}
/* 卡片樣式 */
/* .ant-card-body, .ant-card-actions{
  background: #26212a;
  color: #fff;
  border:none;
} */

.ant-card-meta-title,.ant-card-meta-description{
  color: #fff
}
.ant-card-meta-avatar span{
  background: #fff;
}
.ant-card-hoverable:hover{
  box-shadow: 0 1px 2px -2px #6e6d6d, 0 3px 6px 0 #6e6d6d, 0 5px 12px 4px #6e6d6d;
}

.ant-card-actions > li:not(:last-child) {
  border-right: none;
}

/* Divider */
.ant-divider-horizontal.ant-divider-with-text{
  border-top-color: #fff;
}
/* modal  */
.ant-modal-body {
  /* background: #26212a; */
}
.ant-modal-close-x {
  color: gray;
}

/* upload */
.ant-upload-list.ant-upload-list-picture{
  color: #fff;
}

.ant-upload-list.ant-upload-list-picture button span{
  color:rgb(210, 85, 85);
}

/* form items 不符合格式的錯誤提示文字 */
.ant-form-item-explain-error {
  font-size: 12px;
}

/* radio文字顏色 */
.ant-radio-wrapper {
  /* color: #fff; */
}

.ant-radio-disabled + span{
  /* color: #fff; */
}
/* 
::-webkit-scrollbar {
  height: 12px;
  width: 10px;
}
::-webkit-scrollbar-thumb  {
  border-radius: 5px;
  box-shadow: inset 0 0 5px #3c3244;
  background: #26212a;
  background: #dbdbdb;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b062d;
}

::-webkit-scrollbar-corner{
  background-color:#1b062d;
} */

/** areaModal */
.areaModal .ant-tabs-tab{
  color:#fff
}

/** 視頻管理 封面*/
.cover .ant-form-item-control-input-content{
  display: flex;
}

/* 複製連結的提示視窗 */
.copyParagraph{
  background-color: #5A5A5A;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px!important;
  width:100px!important;
  border-radius:10px!important;
  -webkit-border-radius:10px!important;
  -moz-border-radius:10px!important;
  -ms-border-radius:10px!important;
  -o-border-radius:10px!important;
}

.copyParagraph .ant-notification-notice-closable .ant-notification-notice-message{
  padding: 0;
}
.copyParagraph .ant-notification-notice-message{
  color: #fff!important;
  padding: 0;
  margin:0;
}

.copyParagraph .ant-notification-notice-close{
  display: none;
}

